/**
 * Specific stylin of the payment input form provided by stripe.
 * The following code was adapted form what is provded by default by stripe.
 */
/* Form spacing. */
.payment-form-container form {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #ffffff;
}

#payment-message {
  color: #697386;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons. */
#payment-form button {
  color: #f2f2f2;
  background: #1a1a1a;
  border-radius: 1.2rem;
  border: 0;
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
  display: block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
}

#payment-form button:hover {
  background: #000000;
}

#payment-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* Spinner/processing state, errors. */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: inset 0 0 0 2px;
          box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
