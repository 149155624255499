/*
 * Purpose:
 * This file includes all animations for the whole project. 
 *
 * Contents:
 *
 ** [ STANDARD ANIMATIONS ]
 *
 ** [ NAVBAR ]
 *
 ** [ SIDEBAR ]
 *
 ** [ TABLES ]
 *
 */
/* /////////////////////////////////////////////////////////////////////////
  * [ STANDARD ANIMATIONS ]
  */
@-webkit-keyframes blur {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes blur {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* /////////////////////////////////////////////////////////////////////////
 * [ NAVBAR ]
 */
@-webkit-keyframes navFade {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes navFade {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

/* /////////////////////////////////////////////////////////////////////////
 * [ SIDEBAR ]
 */
@-webkit-keyframes sidebarFadeOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes sidebarFadeOut {
  from {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@-webkit-keyframes sidebarFadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes sidebarFadeIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* /////////////////////////////////////////////////////////////////////////
 * [ TABLES ]
 */
/* Remove table row. */
@-webkit-keyframes tableRowCollapse {
  from {
    opacity: 1;
  }
  to {
    height: 0;
  }
}
@keyframes tableRowCollapse {
  from {
    opacity: 1;
  }
  to {
    height: 0;
  }
}

.tr-collapse-animation {
  -webkit-animation: tableRowCollapse 1.2s ease forwards;
          animation: tableRowCollapse 1.2s ease forwards;
}

/* /////////////////////////////////////////////////////////////////////////
 * [ DROPDOWN MENUS ]
 */
@-webkit-keyframes dropdownRolldown {
  from {
    height: 0;
  }
  to {
    height: 1;
  }
}
@keyframes dropdownRolldown {
  from {
    height: 0;
  }
  to {
    height: 1;
  }
}

@-webkit-keyframes dropdownRollup {
  from {
    height: 1;
  }
  to {
    height: 0;
  }
}

@keyframes dropdownRollup {
  from {
    height: 1;
  }
  to {
    height: 0;
  }
}

.dropdown-rolldown-animation {
  -webkit-animation: dropdownRolldown .6s ease forwards;
          animation: dropdownRolldown .6s ease forwards;
}

.dropdown-rollup-animation {
  -webkit-animation: dropdownRollup 0.4s ease forwards;
          animation: dropdownRollup 0.4s ease forwards;
}
